export class AdminCliApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Current User
     * Return details of the currently logged-in user
     * @returns any Successful Response
     * @throws ApiError
     */
    getCurrentUser() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/current-user',
        });
    }
    /**
     * Migrate User
     * Migrate logged-in user (sync keycloak to neo4j)
     * @returns any Successful Response
     * @throws ApiError
     */
    migrateUser() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/migrate',
        });
    }
    /**
     * Migrate User Admin
     * Migrate specified user (sync keycloak to neo4j)
     * @param username
     * @returns any Successful Response
     * @throws ApiError
     */
    migrateUserAdmin(username) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/admin-migrate',
            query: {
                'username': username,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Identity Providers
     * @returns any Successful Response
     * @throws ApiError
     */
    getIdentityProviders() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/identity-providers',
        });
    }
    /**
     * Get Idp Config
     * @returns any Successful Response
     * @throws ApiError
     */
    getIdpConfig() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/idp-configuration',
        });
    }
    /**
     * Get Password Policy
     * @returns any Successful Response
     * @throws ApiError
     */
    getPasswordPolicy() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/password_policy',
        });
    }
    /**
     * Get Node Permissions
     * @param nodeId
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodePermissions(nodeId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/rbac/{node_id}',
            path: {
                'node_id': nodeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Node Permissions
     * @param nodeId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    setNodePermissions(nodeId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/rbac/{node_id}',
            path: {
                'node_id': nodeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Nodes Labels
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    getNodesLabels(requestBody) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/rbac/labels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Role Privileges
     * Returns full entry of Role based on role name, with any Neo4J permissions
     * @param roleName
     * @returns any Successful Response
     * @throws ApiError
     */
    getRolePrivileges(roleName) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/{role_name}/privileges',
            path: {
                'role_name': roleName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Banner
     * @returns any Successful Response
     * @throws ApiError
     */
    getBanner() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/banner',
        });
    }
    /**
     * Update Banner
     * @param message
     * @param ttl
     * @returns any Successful Response
     * @throws ApiError
     */
    updateBanner(message, ttl) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/banner',
            query: {
                'message': message,
                'ttl': ttl,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Banner
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteBanner() {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/banner',
        });
    }
}

import axiosIns from '@/libs/axios'
import router from '@/router'

const getDefaultState = () => ({
  configurations: [],
  selectedConfiguration: false,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_CONFIGURATIONS: (state, configurations) => {
      state.configurations = configurations
    },
    SET_SELECTED_CONFIGURATION: (state, configuration) => { state.selectedConfiguration = configuration },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    clearConfigurations: ({ commit }) => commit('CLEAR_ALL'),
    getConfigurations: ({ commit }, sorting = 'end') => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId, sorting }
      return axiosIns
        .get('/api/v2/domain_model/build_configurations', { params })
        .then(({ data }) => commit('SET_CONFIGURATIONS', data))
        .catch(e => console.error(e))
    },
    selectConfiguration: ({ commit }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .get(`/api/v2/domain_model/build_configuration/${id}`, { params })
        .then(({ data }) => commit('SET_SELECTED_CONFIGURATION', data))
        .catch(e => console.error(e))
    },
    addConfiguration: ({ dispatch, rootState }, data) => {
      const { modelId } = router.currentRoute.params
      const params = {
        model: modelId,
        user: rootState.auth.email,
      }
      data.model = params.model
      return axiosIns
        .post('/api/v2/domain_model/build_configurations', data, { params })
        .then(() => dispatch('getConfiguration'))
        .catch(e => console.error(e))
    },
    updateConfiguration: ({ dispatch, rootState }, data) => {
      const { modelId } = router.currentRoute.params
      const params = {
        model: modelId,
        user: rootState.auth.email,
      }
      data.model = params.model
      return axiosIns
        .put(`/api/v2/domain_model/build_configuration/${data.id}`, data, { params })
        .then(() => dispatch('getConfigurations'))
        .catch(e => console.error(e))
    },
    deleteConfiguration: ({ dispatch }, id) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .delete(`/api/v2/domain_model/build_configuration/${id}`, { params })
        .then(() => dispatch('getConfigurations'))
        .catch(e => console.error(e))
    },
  },
}

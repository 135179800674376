export class QualificationRecordsApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Create Qualification Record
     * @param modelId
     * @param requestBody
     * @returns QualificationRecord Successful Response
     * @throws ApiError
     */
    createQualificationRecord(modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/qualification_records',
            headers: {
                'model-id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Qualification Record
     * @param id
     * @param modelId
     * @returns QualificationRecord Successful Response
     * @throws ApiError
     */
    getQualificationRecord(id, modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/qualification_records/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Qualification Record
     * @param id
     * @param modelId
     * @param ifMatch
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteQualificationRecord(id, modelId, ifMatch) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/qualification_records/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Qualification Record
     * @param id
     * @param modelId
     * @param ifMatch
     * @param requestBody
     * @returns QualificationRecord Successful Response
     * @throws ApiError
     */
    updateQualificationRecord(id, modelId, ifMatch, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/qualification_records/{id}',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Qualification Records In Requirement
     * @param reqId
     * @param modelId
     * @param withEvidence
     * @param sortBy
     * @param direction
     * @param skip
     * @param limit
     * @param requestBody
     * @returns QualificationRecord Successful Response
     * @throws ApiError
     */
    getQualificationRecordsInRequirement(reqId, modelId, withEvidence = false, sortBy = 'Created', direction = 'DESC', skip, limit, requestBody) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/qualification_records/in_requirement/{req_id}',
            path: {
                'req_id': reqId,
            },
            headers: {
                'model-id': modelId,
            },
            query: {
                'with_evidence': withEvidence,
                'sortBy': sortBy,
                'direction': direction,
                'skip': skip,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Link Evidence
     * @param id
     * @param modelId
     * @param ifMatch
     * @param requestBody
     * @returns EdgeHasEvidence Successful Response
     * @throws ApiError
     */
    linkEvidence(id, modelId, ifMatch, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/qualification_records/{id}/link',
            path: {
                'id': id,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Unlink Evidence
     * @param id
     * @param evidenceId
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    unlinkEvidence(id, evidenceId, modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/qualification_records/{id}/link/{evidence_id}',
            path: {
                'id': id,
                'evidence_id': evidenceId,
            },
            headers: {
                'model-id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Evidence Link
     * @param id
     * @param evidenceId
     * @param modelId
     * @param ifMatch
     * @param requestBody
     * @returns EdgeHasEvidence Successful Response
     * @throws ApiError
     */
    updateEvidenceLink(id, evidenceId, modelId, ifMatch, requestBody) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/qualification_records/{id}/link/{evidence_id}',
            path: {
                'id': id,
                'evidence_id': evidenceId,
            },
            headers: {
                'model-id': modelId,
                'if-match': ifMatch,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Baseline Evidence
     * @param modelId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    baselineEvidence(modelId, formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/qualification_records/baseline',
            headers: {
                'model-id': modelId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}

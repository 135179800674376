import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/configurations',
    name: 'model_configurations',
    component: () => import('@/views/Configurations/Configurations.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Configurations',
      auth: true,
      breadcrumb: [
        {
          text: 'Configurations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/configurations?id=:id',
    name: 'configuration_details',
    component: () => import('@/views/Configurations/Configuration.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Build Configuration Details',
      auth: true,
      breadcrumb: [
        {
          text: 'Build Configurations',
          to: {
            name: 'model_configurations',
          },
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
]

export default routes

export default [
  {
    headerKey: 2,
    header: 'Models',
  },
  {
    title: 'Browse',
    route: 'model_browse',
    icon: 'SearchIcon',
  },
  {
    title: 'Create',
    route: 'create_model',
    icon: ['fas', 'project-diagram'],
    iconLibrary: 'font-awesome',
  },
]

import coreService from '@/libs/api-services/core-service'
import router from '@/router'
import utilsDragAndDrop from '@/views/RequirementsTable/utils-dragAndDrop'
import utilsLazyLoad from '@/views/RequirementsTable/utils-lazyLoad'
import { nextTick } from '@vue/composition-api'

const { registerLazyLoadObserver, unregisterLazyLoadObserver, resetRowHeights } = utilsLazyLoad()
const { registerDragAndDrop, unregisterDragAndDrop } = utilsDragAndDrop()

const COLUMNS_REQUIREMENTS_KEY = 'requirementsTableShowingColumns'
/// Get the visible requirements table columns from the nearest storage
// vuex -> session -> localstorage
// Creates the session storage entry if a localstorage entry is found
function getSavedColumns() {
  const savedSessionColumns = window.sessionStorage.getItem(COLUMNS_REQUIREMENTS_KEY)
  let savedLocalStorageColumns = ''
  if (!savedSessionColumns) {
    savedLocalStorageColumns = window.localStorage.getItem(COLUMNS_REQUIREMENTS_KEY)
    if (savedLocalStorageColumns) {
      window.sessionStorage.setItem('columns', savedLocalStorageColumns)
    }
  }
  return savedSessionColumns || savedLocalStorageColumns
}

const getDefaultState = () => {
  const defaultState = {
    allColumns: [],
    columns: [
      {
        value: 'section',
        text: 'Section',
      },
      {
        value: 'display_id',
        text: 'Display Id',
      },
      {
        value: 'priority',
        text: 'Priority',
      },
      {
        value: 'classification',
        text: 'Classification',
      },
      {
        value: 'object_text',
        text: 'Object Text',
      },
    ],
    allRequirements: [],
    loadingRequirements: false,
    skip: 0,
    limit: 3000,
  }
  const savedColumns = getSavedColumns()
  if (savedColumns) {
    defaultState.columns = JSON.parse(savedColumns)
  }
  return defaultState
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    isColumnShowing: state => searchColumn => state.columns.find(item => item.value === searchColumn),
    resolveRequirementObjects: state => selectedRowIndexes => selectedRowIndexes.map(index => state.allRequirements[index]).filter(obj => obj !== undefined),
    resolveRequirementIds: state => selectedRowIndexes => selectedRowIndexes.map(index => state.allRequirements[index].id),
  },
  mutations: {
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
    SET_ALL_REQUIREMENTS: (state, data) => { state.allRequirements = data },
    SET_ALL_COLUMNS: (state, data) => {
      state.allColumns = data.map((item, index) => ({
        value: item,
        text: item.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
      }))
    },
    SET_SHOWN_COLUMNS: (state, data) => {
      state.columns = data
      window.localStorage.setItem(COLUMNS_REQUIREMENTS_KEY, JSON.stringify(state.columns))
      window.sessionStorage.setItem(COLUMNS_REQUIREMENTS_KEY, JSON.stringify(state.columns))
    },
    SET_LOADING_ALL_REQUIREMENTS: (state, val) => { state.loadingRequirements = val },
    RESET_COLUMNS_TO_DEFAULT: state => {
      state.columns = [
        {
          value: 'section',
          text: 'Section',
        },
        {
          value: 'display_id',
          text: 'Display Id',
        },
        {
          value: 'priority',
          text: 'Priority',
        },
        {
          value: 'classification',
          text: 'Classification',
        },
        {
          value: 'object_text',
          text: 'Object Text',
        },
      ]
    },
    INJECT_UPDATED_REQUIREMENT: (state, reqObj) => {
      const index = state.allRequirements.findIndex(r => r.id === reqObj.id)
      if (index !== -1) {
        const updatedRequirement = { ...state.allRequirements[index], ...reqObj }
        Object.assign(state.allRequirements[index], updatedRequirement)
      }
    },
  },
  actions: {
    getAllRequirements({
      commit, dispatch, getters, state,
    }) {
      const { modelId, specId } = router.currentRoute.params
      const requestBody = {
        columns: state.columns,
        skip: state.skip,
        limit: state.limit,
      }
      commit('SET_LOADING_ALL_REQUIREMENTS', true)

      unregisterLazyLoadObserver()
      unregisterDragAndDrop()

      return coreService.specificationsApi
        .getRequirementsV2(specId, modelId, requestBody)
        .then(response => {
          commit('SET_ALL_REQUIREMENTS', response)

          // Load additional meta columns
          if (getters.isColumnShowing('behaviours')) { dispatch('getAllBehaviours') }
          if (getters.isColumnShowing('components')) { dispatch('getAllComponents') }
          if (getters.isColumnShowing('coverage')) { dispatch('getAllCoverage') }
          if (getters.isColumnShowing('interfaces')) { dispatch('getAllInterfaces') }
          if (getters.isColumnShowing('issues')) { dispatch('getAllIssues') }
          if (getters.isColumnShowing('notes')) { dispatch('getAllNotes') }
          if (getters.isColumnShowing('releases')) { dispatch('getAllReleases') }
          if (getters.isColumnShowing('tests')) { dispatch('getAllTests') }
          if (getters.isColumnShowing('trace')) { dispatch('getAllTrace') }
          if (getters.isColumnShowing('qualification_records')) { dispatch('getAllQualificationRecords') }

          nextTick(() => {
            // Once the requirements are loaded, observe all the rows
            registerLazyLoadObserver()
            registerDragAndDrop()
            resetRowHeights()
          })
        })
        .catch(error => console.error(error))
        .finally(() => { commit('SET_LOADING_ALL_REQUIREMENTS', false) })
    },
    getAllColumns({ commit }) {
      commit('SET_ALL_COLUMNS', [])
      const { modelId, specId } = router.currentRoute.params

      return coreService.specificationsApi
        .getSpecificationColumns(specId, modelId)
        .then(response => {
          commit('SET_ALL_COLUMNS', response)
        })
        .catch(error => console.error(error))
    },
    getAdditionalCustomColumns() {
      const { modelId, specId } = router.currentRoute.params

      return coreService.specificationsApi
        .getSpecificationColumns(specId, modelId, true)
        .then(response => response)
        .catch(error => console.error(error))
    },
    setShownColumns({ commit }, columnsToShow) {
      commit('SET_SHOWN_COLUMNS', columnsToShow)
    },
    handleDragMove({ commit, dispatch }, payload) {
      dispatch('requirements/moveRequirement', payload, { root: true })
        .then(() => {
          dispatch('getAllRequirements')
        })
        .catch(error => console.error(error))
    },
    getAllBehaviours({ state }) {
      const { modelId, specId } = router.currentRoute.params
      return coreService.specificationsApi
        .getSpecificationBehaviours(specId, modelId)
        .then(response => {
          state.allRequirements.forEach(item => {
            item.behaviours = Object.hasOwn(response, item.id) ? response[item.id] : []
          })
        })
        .catch(error => console.error(error))
    },
    getAllIssues({ state }) {
      const { modelId, specId } = router.currentRoute.params
      return coreService.specificationsApi
        .getSpecificationIssues(specId, modelId)
        .then(response => {
          state.allRequirements.forEach(item => {
            item.issues = Object.hasOwn(response, item.id) ? response[item.id] : []
          })
        })
        .catch(error => console.error(error))
    },
    getAllTests({ state }) {
      const { modelId, specId } = router.currentRoute.params
      return coreService.specificationsApi
        .getSpecificationTests(specId, modelId)
        .then(response => {
          state.allRequirements.forEach(item => {
            item.tests = Object.hasOwn(response, item.id) ? response[item.id] : []
          })
        })
        .catch(error => console.error(error))
    },
    getAllComponents({ state }) {
      const { modelId, specId } = router.currentRoute.params
      return coreService.specificationsApi
        .getSpecificationComponents(specId, modelId)
        .then(response => {
          state.allRequirements.forEach(item => {
            item.components = Object.hasOwn(response, item.id) ? response[item.id] : []
          })
        })
        .catch(error => console.error(error))
    },
    getAllQualificationRecords({ state }) {
      const { modelId, specId } = router.currentRoute.params
      return coreService.specificationsApi
        .getSpecificationQualificationRecords(specId, modelId)
        .then(response => {
          state.allRequirements.forEach(item => {
            item.qualification_records = Object.hasOwn(response, item.id) ? response[item.id] : []
          })
        })
        .catch(error => console.error(error))
    },
    getAllReleases({ state }) {
      const { modelId, specId } = router.currentRoute.params
      return coreService.specificationsApi
        .getSpecificationReleases(specId, modelId)
        .then(response => {
          state.allRequirements.forEach(item => {
            item.releases = Object.hasOwn(response, item.id) ? response[item.id] : []
          })
        })
        .catch(error => console.error(error))
    },
    getAllInterfaces({ state }) {
      const { modelId, specId } = router.currentRoute.params
      return coreService.specificationsApi
        .getSpecificationInterfaces(specId, modelId)
        .then(response => {
          state.allRequirements.forEach(item => {
            item.interfaces = Object.hasOwn(response, item.id) ? response[item.id] : []
          })
        })
        .catch(error => console.error(error))
    },
    getAllTrace({ state }) {
      const { modelId, specId } = router.currentRoute.params
      return coreService.specificationsApi
        .getSpecificationTrace(specId, modelId)
        .then(response => {
          state.allRequirements.forEach(item => {
            item.trace = Object.hasOwn(response, item.id) ? response[item.id] : []
          })
        })
        .catch(error => console.error(error))
    },
    getAllCoverage({ state }) {
      const { modelId, specId } = router.currentRoute.params
      return coreService.specificationsApi
        .getSpecificationCoverage(specId, modelId)
        .then(response => {
          state.allRequirements.forEach(item => {
            item.coverage = Object.hasOwn(response, item.id) ? response[item.id] : []
          })
        })
        .catch(error => console.error(error))
    },
    getAllNotes({ state }) {
      const { modelId, specId } = router.currentRoute.params
      return coreService.specificationsApi
        .getSpecificationNotes(specId, modelId)
        .then(response => {
          state.allRequirements.forEach(item => {
            item.notes = Object.hasOwn(response, item.id) ? response[item.id] : []
          })
        })
        .catch(error => console.error(error))
    },
    bulkDeleteRequirements({ state }, reqIdList) {
      const { modelId } = router.currentRoute.params
      return coreService.requirementsApi
        .bulkDeleteRequirements(modelId, reqIdList)
        .catch(e => { console.error(`Error bulk deleting requirements: ${e}`) })
    },
    bulkSetRequirementSecurityClassification({ commit }, payload) {
      const { modelId } = router.currentRoute.params
      const { reqIdList, classification } = payload
      return coreService.requirementsApi
        .updateBulkRequirements(modelId, {
          data: { classification },
          req_id_list: reqIdList,
        })
        .then(response => { response.forEach(req => { commit('INJECT_UPDATED_REQUIREMENT', req) }) })
        .catch(e => { console.error(`Error bulk updating requirement classifications: ${e}`) })
    },
    bulkSetRequirementPriority({ commit }, payload) {
      const { modelId } = router.currentRoute.params
      const { reqIdList, priority } = payload
      return coreService.requirementsApi
        .updateBulkRequirements(modelId, {
          data: { priority },
          req_id_list: reqIdList,
        })
        .then(response => { response.forEach(req => { commit('INJECT_UPDATED_REQUIREMENT', req) }) })
        .catch(e => { console.error(`Error bulk updating requirement priority: ${e}`) })
    },
    bulkSetRequirementStatus({ commit }, payload) {
      const { modelId } = router.currentRoute.params
      const { reqIdList, status } = payload
      return coreService.requirementsApi
        .updateBulkRequirements(modelId, {
          data: { status },
          req_id_list: reqIdList,
        })
        .then(response => { response.forEach(req => { commit('INJECT_UPDATED_REQUIREMENT', req) }) })
        .catch(e => { console.error(`Error bulk updating requirement status: ${e}`) })
    },
    clearRequirementsTableStore: ({ commit }) => {
      unregisterLazyLoadObserver()
      commit('CLEAR_ALL')
    },
  },
}

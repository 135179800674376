import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/specification/:specId/requirements/:focus?',
    name: 'requirements_table_focus_on_id',
    component: () => import('@/views/RequirementsTable/RequirementsTable.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Requirements',
      auth: true,
      dynamicTitle: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
          },
        },
        {
          text: 'Requirements',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/specification/:specId/requirements',
    name: 'requirements_table',
    component: () => import('@/views/RequirementsTable/RequirementsTable.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Requirements',
      auth: true,
      dynamicTitle: true,
      maintainDefaultBreadcrumb: true,
      breadcrumb: [
        {
          text: 'Specifications',
          to: {
            name: 'all_specifications_table',
          },
        },
        {
          text: 'Requirements',
          active: true,
        },
      ],
    },
  },
]

export default routes

export class ModelApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Snapshot
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getSnapshot(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/snapshot',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Snapshot
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    createSnapshot(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/model/{model_id}/snapshot',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Snapshot
     * @param modelId
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteSnapshot(modelId, snapshotId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/model/{model_id}/snapshot/{snapshot_id}',
            path: {
                'model_id': modelId,
                'snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Convert Snapshot
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    convertSnapshot(modelId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/model/{model_id}/snapshot/convert',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Temp Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteTempModel(modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/model/{model_id}/temp_model',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Import Snapshot
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    importSnapshot(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/model/snapshot/import',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Download Snapshot
     * @param modelId
     * @param snapshotId
     * @returns any Successful Response
     * @throws ApiError
     */
    downloadSnapshot(modelId, snapshotId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/snapshot/{snapshot_id}/download',
            path: {
                'model_id': modelId,
                'snapshot_id': snapshotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getModel(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteModel(modelId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/model/{model_id}',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Temp Models
     * @returns any Successful Response
     * @throws ApiError
     */
    getTempModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/temp',
        });
    }
    /**
     * Get All Models
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllModels() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/all',
        });
    }
    /**
     * Get Models Shared
     * @returns any Successful Response
     * @throws ApiError
     */
    getModelsShared() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/shared',
        });
    }
    /**
     * Get Models Public
     * @returns any Successful Response
     * @throws ApiError
     */
    getModelsPublic() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/public',
        });
    }
    /**
     * Get Models By User
     * @returns any Successful Response
     * @throws ApiError
     */
    getModelsByUser() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/user',
        });
    }
    /**
     * Get All Components
     * @param modelId
     * @param subtype
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllComponents(modelId, subtype) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/components',
            path: {
                'model_id': modelId,
            },
            query: {
                'subtype': subtype,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Linked Models
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    getLinkedModels(modelId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/links',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Query Model Link
     * @param modelId
     * @param model2Id
     * @returns any Successful Response
     * @throws ApiError
     */
    queryModelLink(modelId, model2Id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/model/{model_id}/link/{model2_id}',
            path: {
                'model_id': modelId,
                'model2_id': model2Id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Model Link
     * @param modelId
     * @param model2Id
     * @returns any Successful Response
     * @throws ApiError
     */
    createModelLink(modelId, model2Id) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/model/{model_id}/link/{model2_id}',
            path: {
                'model_id': modelId,
                'model2_id': model2Id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Model Link
     * @param modelId
     * @param model2Id
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteModelLink(modelId, model2Id) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/model/{model_id}/link/{model2_id}',
            path: {
                'model_id': modelId,
                'model2_id': model2Id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}

const routes = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/auth/views/Login.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/auth/views/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/users',
    name: 'admin_users',
    component: () => import('@/auth/views/admin/AdminUsersTable.vue'),
    meta: {
      pageTitle: 'User Admin',
      auth: true,
      breadcrumb: [
        {
          text: 'User Admin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/groups',
    name: 'admin_groups',
    component: () => import('@/auth/views/admin/AdminGroupsTable.vue'),
    meta: {
      pageTitle: 'Group Admin',
      auth: true,
      breadcrumb: [
        {
          text: 'Group Admin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/roles',
    name: 'admin_roles',
    component: () => import('@/auth/views/admin/AdminRolesTable.vue'),
    meta: {
      pageTitle: 'Role Admin',
      auth: true,
      breadcrumb: [
        {
          text: 'Role Admin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/banner',
    name: 'admin_banner',
    component: () => import('@/auth/views/admin/AdminBanner.vue'),
    meta: {
      pageTitle: 'Announcement Banner',
      auth: true,
      breadcrumb: [
        {
          text: 'Announcement Banner',
          active: true,
        },
      ],
    },
  },
]

export default routes

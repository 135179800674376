export class RoleManagementApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get All Roles
     * Get all roles of the Keycloak realm (Kompozition)
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllRoles() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/roles',
        });
    }
    /**
     * Update Role
     * Update the permission level of a role
     * @param roleName
     * @param permissionLevel
     * @returns any Successful Response
     * @throws ApiError
     */
    updateRole(roleName, permissionLevel) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/roles',
            query: {
                'role_name': roleName,
                'permission_level': permissionLevel,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Role
     * Create a role on the Kompozition realm
     * @param roleName
     * @param permissionLevel
     * @returns any Successful Response
     * @throws ApiError
     */
    createRole(roleName, permissionLevel) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/roles',
            query: {
                'role_name': roleName,
                'permission_level': permissionLevel,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Role
     * Deletes a role on the Kompozition realm
     * @param roleName
     * @returns any Successful Response
     * @throws ApiError
     */
    deleteRole(roleName) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/roles',
            query: {
                'role_name': roleName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Role
     * Returns full entry of Role based on role name, with any Neo4J permissions
     * @param roleName
     * @returns CompositeRole Successful Response
     * @throws ApiError
     */
    getRole(roleName) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/roles/{role_name}',
            path: {
                'role_name': roleName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}

<template>
  <div id="app" class="h-100" :class="[skinClasses]">

    <div id="banner-container">
      <!-- Model Snapshot Banner -->
      <div
        v-if="$store.state.model.id && $store.state.model.database"
        id="snapshot-banner"
        class="w-100 d-inline-flex justify-content-center bg-danger"
      >
        <span class="banner-text font-small-2 my-25">
          <span class="font-weight-bold mr-2">SNAPSHOT WORKSPACE</span>
          <span>[ {{ $store.state.model.name }} ]</span>
        </span>
      </div>

      <!-- Announcement Banner -->
      <div
        v-if="bannerMessage && !bannerDismissed"
        id="announcement-banner"
        class="bg-info"
      >
        <div class="w-100 d-inline-flex align-items-center justify-content-center">
          <span class="banner-text font-medium-1 font-weight-bold">
            {{ bannerMessage }}
          </span>
          <b-button variant="flat-secondary" class="banner-dismiss" @click="dismissBanner">
            <feather-icon icon="XIcon" size="16" />
          </b-button>
        </div>
      </div>
    </div>

    <!-- Page Layout -->
    <component :is="layout">
      <router-view />
    </component>

    <div id="global-modals">
      <update-note-modal />
      <delete-note-modal />
    </div>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import updateToken from '@/auth/middleware/updateToken'
import { useWindowSize, useCssVar } from '@vueuse/core'

import getEnv from '@/utils/env'
import store from '@/store'

// Global modals
import UpdateNoteModal from '@/components/Notes/Modals/UpdateNoteModal.vue'
import DeleteNoteModal from '@/components/Notes/Modals/DeleteNoteModal.vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    // Global modals
    UpdateNoteModal,
    DeleteNoteModal,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    bannerMessage() {
      return this.$store.state.app.bannerMessage
    },
    bannerDismissed() {
      return this.$store.state.app.bannerDismissed
    },
  },
  watch: {
    $route(to, from) {
      document.title = `${to.meta.pageTitle} | Kompozition_` || 'Kompozition_'
      updateToken()
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    const existingModelWorkspace = sessionStorage.getItem('kompozition-workspace')
    const tempSnapshotWorkspace = sessionStorage.getItem('X-Target')
    if (existingModelWorkspace) {
      console.log(`Reopening model workspace for session: ${existingModelWorkspace}`)
      if (tempSnapshotWorkspace) {
        const model = {}
        model.id = existingModelWorkspace
        model.database = tempSnapshotWorkspace
        store.dispatch('model/openTempWorkspace', model)
      } else {
        store.dispatch('model/openWorkspace', existingModelWorkspace)
      }
    } else {
      console.warn(`Model id is not defined. Got: ${existingModelWorkspace}`)
    }

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })
    store.dispatch('auth/getUserData')

    // Fetch constants values from API
    store.dispatch('constants/fetchConstants')

    const dismissBanner = () => {
      store.commit('app/DISMISS_BANNER')
    }
    return {
      skinClasses,
      dismissBanner,
      tempSnapshotWorkspace,
    }
  },
}
</script>

<style lang="scss">
/* width */
::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

::-webkit-scrollbar-corner {
  display: none;
}

/* Track */
::-webkit-scrollbar-track:hover {
  background: rgba(57, 64, 81, 0.2);
}

.dark-layout {
  ::-webkit-scrollbar-track:hover {
    background: rgba(57, 64, 81, 1);
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(157, 164, 182, 0.5);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(124, 129, 141, 1);
}

#banner-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1034;
}

#announcement-banner {
  .banner-text {
    color: white;
    text-shadow: 3px 3px 1rem black;
    margin: 0.25rem;
  }
  .banner-dismiss {
    position: absolute;
    right: 1rem;
    color: white;
  }
}

#snapshot-banner {
  .banner-text {
    color: white;
    text-shadow: 3px 3px 1rem black;
  }
}
</style>

export default [
  {
    headerKey: 7,
    header: 'Administration',
  },
  {
    title: 'Import Snapshot',
    route: 'import_model_snapshot',
    icon: ['fas', 'file-import'],
    iconLibrary: 'font-awesome',
  },
  {
    title: 'Manage',
    icon: 'GlobeIcon',
    children: [
      {
        title: 'Users',
        route: 'admin_users',
        icon: 'UserIcon',
      },
      {
        title: 'Roles',
        route: 'admin_roles',
        icon: 'UserCheckIcon',
      },
      {
        title: 'Groups',
        route: 'admin_groups',
        icon: 'UsersIcon',
      },
      {
        title: 'Banner',
        route: 'admin_banner',
        icon: 'MessageSquareIcon',
      },
    ],
  },
]

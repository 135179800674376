import axiosIns from '@/libs/axios'

const getDefaultState = () => ({
  behaviourOperators: [],
  constraintLevels: [],
  criticalities: [],
  fics: [],
  ideaTypes: [],
  interfaceTypes: [],
  interfaceStatus: [],
  irls: [],
  issueClassifications: [],
  issueSeverities: [],
  issueStatuses: [],
  lcPhases: [],
  modelStatuses: [],
  noteTypes: [],
  operationalStatus: [],
  protectedReqAttributes: [],
  qualificationMethods: [],
  qualificationPhases: [],
  qualificationResults: [],
  qualityAttributes: [],
  releaseAllocationStatus: [],
  requirementPriorities: [],
  requirementProperties: [],
  requirementStatuses: [],
  requirementTypes: [],
  roles: [],
  securityClassifications: [],
  stereotypes: [],
  trls: [],
  usages: [],
  validities: [],
  defaultCriticality: {},
  defaultIdeaType: {},
  defaultIssueClassification: {},
  defaultIssueSeverity: {},
  defaultIssueStatus: {},
  defaultLcPhase: {},
  defaultModelStatus: {},
  defaultNoteType: {},
  defaultOperationalStatus: {},
  defaultTrl: {},
  defaultQualificationMethod: {},
  defaultRequirementPriority: {},
  defaultRequirementStatus: {},
  defaultRequirementType: {},
  defaultSecurityClassification: {},
  defaultUsage: {},
  defaultValidity: {},
})

function mapToSelectElement(array, value, text) {
  /**
   * Map an array to be compatible with HTML select element,
   * while retaining all original values.
   * @function mapToSelectElement
   * @private
   * @param   {Array}   array The array to map.
   * @param   {String}  value The object property to map to the `value` property.
   * @param   {String}  text  The object property to map to the `text` property.
   * @return  {Array}   The original array with mapped `value` and `text` properties.
   */
  return array.map(el => Object({
    value: el[value],
    text: el[text],
    ...el,
  }))
}

function resolveConstant(array, property, value) {
  /**
   * Find the object in the array with the given `property` `value`.
   * @function resolveConstant
   * @private
   * @param   {Array}   array     The array to search.
   * @param   {String}  property  The property to compare against.
   * @param   {String}  value     The value to compare with.
   * @return  {Object}  The found object if it exists.
   */
  return array.find(el => el[property] === value)
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    behaviourOperators: state => mapToSelectElement(state.behaviourOperators, 'id', 'text'),
    constraintLevels: state => mapToSelectElement(state.constraintLevels, 'id', 'name'),
    criticalities: state => mapToSelectElement(state.criticalities, 'id', 'name'),
    fics: state => mapToSelectElement(state.fics, 'id', 'name'),
    ideaTypes: state => mapToSelectElement(state.ideaTypes, 'id', 'name'),
    interfaceTypes: state => mapToSelectElement(state.interfaceTypes, 'id', 'name'),
    interfaceStatus: state => mapToSelectElement(state.interfaceStatus, 'id', 'name'),
    irls: state => mapToSelectElement(state.irls, 'id', 'description'),
    issueClassifications: state => mapToSelectElement(state.issueClassifications, 'id', 'name'),
    issueSeverities: state => mapToSelectElement(state.issueSeverities, 'id', 'name'),
    issueStatuses: state => mapToSelectElement(state.issueStatuses, 'id', 'name'),
    lcPhases: state => mapToSelectElement(state.lcPhases, 'id', 'name'),
    modelStatuses: state => mapToSelectElement(state.modelStatuses, 'id', 'name'),
    noteTypes: state => mapToSelectElement(state.noteTypes, 'id', 'name'),
    operationalStatus: state => mapToSelectElement(state.operationalStatus, 'id', 'name'),
    protectedReqAttributes: state => mapToSelectElement(state.protectedReqAttributes, 'id', 'name'),
    qualificationMethods: state => mapToSelectElement(state.qualificationMethods, 'id', 'name'),
    qualificationPhases: state => mapToSelectElement(state.qualificationPhases, 'id', 'name'),
    qualificationResults: state => mapToSelectElement(state.qualificationResults, 'id', 'name'),
    qualityAttributes: state => mapToSelectElement(state.qualityAttributes, 'name', 'scale'),
    releaseAllocationStatus: state => mapToSelectElement(state.releaseAllocationStatus, 'id', 'name'),
    requirementPriorities: state => mapToSelectElement(state.requirementPriorities, 'id', 'name'),
    requirementStatuses: state => mapToSelectElement(state.requirementStatuses, 'id', 'name'),
    requirementTypes: state => mapToSelectElement(state.requirementTypes, 'id', 'name'),
    roles: state => mapToSelectElement(state.roles, 'id', 'name'),
    securityClassifications: state => mapToSelectElement(state.securityClassifications, 'id', 'name'),
    stereotypes: state => mapToSelectElement(state.stereotypes, 'id', 'name'),
    testPriorities: state => mapToSelectElement(state.testPriorities, 'id', 'name'),
    trls: state => mapToSelectElement(state.trls, 'id', 'name'),
    usages: state => mapToSelectElement(state.usages, 'id', 'name'),
  },
  mutations: {
    SET_CONSTANTS: (state, data) => {
      state.behaviourOperators = data.behaviour_operators
      state.constraintLevels = data.constraint_level
      state.criticalities = data.criticality
      state.fics = data.fics
      state.ideaTypes = data.idea_type
      state.interfaceTypes = data.interface_type
      state.interfaceStatus = data.interface_status
      state.irls = data.irls
      state.issueClassifications = data.issue_classification
      state.issueSeverities = data.issue_severity
      state.issueStatuses = data.issue_status
      state.lcPhases = data.lc_phase
      state.modelStatuses = data.model_status
      state.noteTypes = data.note_types
      state.operationalStatus = data.operational_status
      state.protectedReqAttributes = data.protected_req_attributes
      state.qualificationMethods = data.qualification_methods
      state.qualificationPhases = data.qualification_phase
      state.qualificationResults = data.qualification_result
      state.qualityAttributes = data.quality_attributes
      state.releaseAllocationStatus = data.release_allocation_status
      state.requirementPriorities = data.requirement_priority
      state.requirementProperties = data.default_requirement_properties
      state.requirementStatuses = data.requirement_status
      state.requirementTypes = data.requirement_types
      state.roles = data.roles
      state.securityClassifications = data.security_classifications
      state.stereotypes = data.stereotypes
      state.testPriorities = data.test_priorities
      state.trls = data.trls
      state.usages = data.usage
      state.validities = data.validity

      state.defaultCriticality = data.criticality.find(e => e.default)
      state.defaultIdeaType = data.idea_type.find(e => e.default)
      state.defaultIssueClassification = data.issue_classification.find(e => e.default)
      state.defaultIssueSeverity = data.issue_severity.find(e => e.default)
      state.defaultIssueStatus = data.issue_status.find(e => e.default)
      state.defaultLcPhase = data.lc_phase.find(e => e.default)
      state.defaultModelStatus = data.model_status.find(e => e.default)
      state.defaultNoteType = data.note_types.find(e => e.default)
      state.defaultOperationalStatus = data.operational_status.find(e => e.default)
      state.defaultTrl = data.trls.find(e => e.default)
      state.defaultQualificationMethod = data.qualification_methods.find(e => e.default)
      state.defaultQualificationPhase = data.qualification_phase.find(e => e.default)
      state.defaultQualificationResult = data.qualification_result.find(e => e.default)
      state.defaultRequirementPriority = data.requirement_priority.find(e => e.default)
      state.defaultRequirementStatus = data.requirement_status.find(e => e.default)
      state.defaultRequirementType = data.requirement_types.find(e => e.default)
      state.defaultSecurityClassification = data.security_classifications.find(e => e.default)
      state.defaultTestPriority = data.test_priorities.find(e => e.default)
      state.defaultUsage = data.usage.find(e => e.default)
      state.defaultValidity = data.validity.find(e => e.default)
    },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    fetchConstants: ({ commit }) => axiosIns
      .get('/api/v2/constants')
      .then(({ data }) => {
        commit('SET_CONSTANTS', data)
      })
      .catch(e => console.error(e)),
  },
}

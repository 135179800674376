import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/ontology?focus=:focus',
    name: 'domain_ontology_focus',
    component: () => import('@/views/DomainModel/Ontology.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Ontology',
      auth: true,
      breadcrumb: [
        {
          text: 'Ontology',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/ecosystem',
    name: 'domain_ecosystem',
    component: () => import('@/views/DomainModel/Ecosystem.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Ecosystem',
      auth: true,
      breadcrumb: [
        {
          text: 'Ecosystem',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/ecosystem?id=:id?&force=:force',
    name: 'domain_ecosystem_focus',
    component: () => import('@/views/DomainModel/Ecosystem.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Ecosystem',
      auth: true,
      breadcrumb: [
        {
          text: 'Ecosystem',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/ontology',
    name: 'domain_ontology',
    component: () => import('@/views/DomainModel/Ontology.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Ontology',
      auth: true,
      breadcrumb: [
        {
          text: 'Ontology',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/ontology_treeview',
    name: 'ontology_treeview',
    component: () => import('@/components/Domain/OntologyTreeView/OntologyTreeView.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Ontology Explorer',
      auth: true,
      breadcrumb: [
        {
          text: 'Ontology Explorer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/ontology_class_diagram&focus=:focus',
    name: 'ontology_class_diagram',
    component: () => import('@/components/Domain/ClassDiagram/ClassDiagramJoint.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Ontology Class Diagram',
      auth: true,
      breadcrumb: [
        {
          text: 'Ontology Class Diagram',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/decomp',
    name: 'domain_function',
    component: () => import('@/views/DomainModel/FunctionAllocation.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Functional Analysis',
      auth: true,
      breadcrumb: [
        {
          text: 'Functional Analysis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/ontology/?root=:root&focus=:focus',
    name: 'domain_ontology_focus_with_root',
    component: () => import('@/views/DomainModel/Ontology.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Ontology',
      auth: true,
      breadcrumb: [
        {
          text: 'Ontology',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/functional_analysis?focus=:focus',
    name: 'domain_analysis_focus',
    component: () => import('@/views/DomainModel/FunctionAllocation.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Functional Analysis',
      auth: true,
      breadcrumb: [
        {
          text: 'Functional Analysis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/functional_analysis',
    name: 'domain_analysis',
    component: () => import('@/views/DomainModel/FunctionAllocation.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Functional Analysis',
      auth: true,
      breadcrumb: [
        {
          text: 'Functional Analysis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/system',
    name: 'domain_system',
    component: () => import('@/views/DomainModel/SystemDecomposition.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'System Decomposition and Constraint',
      auth: true,
      breadcrumb: [
        {
          text: 'System Decomposition',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/system?focus=:focus',
    name: 'domain_system_focus',
    component: () => import('@/views/DomainModel/SystemDecomposition.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'System Decomposition and Constraint',
      auth: true,
      breadcrumb: [
        {
          text: 'System Decomposition',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/relations',
    name: 'domain_relations',
    component: () => import('@/views/DomainModel/Relations.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Relations',
      auth: true,
      breadcrumb: [
        {
          text: 'Relations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/relations?focus=:focus',
    name: 'domain_relations_focus',
    component: () => import('@/views/DomainModel/EntityRelations.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Entity Relations',
      auth: true,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: '/model',
        },
        {
          text: 'Entity Relations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/interfaces?focus=:interfaceId',
    name: 'interfaces_focus',
    component: () => import('@/apps/Interfaces/Interfaces.vue'),
    beforeEnter,
    meta: {
      auth: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'Interfaces',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Interfaces',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/interfaces',
    name: 'interfaces',
    component: () => import('@/apps/Interfaces/Interfaces.vue'),
    beforeEnter,
    meta: {
      auth: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'Interfaces',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Interfaces',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/interfaces?status=:status',
    name: 'interfaces_status',
    component: () => import('@/apps/Interfaces/Interfaces.vue'),
    beforeEnter,
    meta: {
      auth: true,
      navActiveLink: 'interfaces',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'Interfaces',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Interfaces',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/interfaces?type=:type',
    name: 'interfaces_type',
    component: () => import('@/apps/Interfaces/Interfaces.vue'),
    meta: {
      auth: true,
      navActiveLink: 'interfaces',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'Interfaces',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Interfaces',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      beforeEnter(to, _, next)
      const allowed = ['structural/mechanical', 'fluid', 'electrical (power)', 'electronic (signal)', 'software and data', 'environment', 'other']
      if (allowed.includes(to.params.type)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/model/:modelId/interfaces?irl=:irl',
    name: 'interfaces_irl',
    component: () => import('@/apps/Interfaces/Interfaces.vue'),
    beforeEnter,
    meta: {
      auth: true,
      navActiveLink: 'interfaces',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'Interfaces',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Interfaces',
          active: true,
        },
      ],
    },
  },
]

export default routes

export class IssuesApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Create Issue Report
     * @param filetype
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createIssueReport(filetype, modelId, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/issues/report',
            headers: {
                'model-id': modelId,
            },
            query: {
                'filetype': filetype,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}

import beforeEnter from '@/router/modules/models/beforeEnter'

const routes = [
  {
    path: '/model/:modelId/issues',
    name: 'app_issues',
    component: () => import('@/views/Issues/app/Issues.vue'),
    beforeEnter,
    meta: {
      auth: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Issues',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Issues',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/issues?filter=:filter',
    name: 'app_issues_filter',
    component: () => import('@/views/Issues/app/Issues.vue'),
    meta: {
      auth: true,
      navActiveLink: 'app_issues',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Issues',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Issues',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/issues?classification=:classification',
    name: 'app_issues_classification',
    component: () => import('@/views/Issues/app/Issues.vue'),
    meta: {
      auth: true,
      navActiveLink: 'app_issues',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Issues',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Issues',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      beforeEnter(to, _, next)
      const allowed = ['ambiguous', 'inaccurate', 'incomplete', 'inconsistent', 'query', 'redundant', 'minor query', 'design query']
      if (allowed.includes(to.params.classification)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/model/:modelId/issues?severity=:severity',
    name: 'app_issues_severity',
    component: () => import('@/views/Issues/app/Issues.vue'),
    meta: {
      auth: true,
      navActiveLink: 'app_issues',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Issues',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Issues',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      beforeEnter(to, _, next)
      const allowed = ['critical', 'major', 'minor', 'negligible', 'query', 'minor query', 'design query']
      if (allowed.includes(to.params.severity)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/model/:modelId/issues?status=:status',
    name: 'app_issues_status',
    component: () => import('@/views/Issues/app/Issues.vue'),
    meta: {
      auth: true,
      navActiveLink: 'app_issues',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Issues',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Issues',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      beforeEnter(to, _, next)
      const allowed = ['new', 'open', 'withdrawn', 'deferred', 'closed']
      if (allowed.includes(to.params.status)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/model/:modelId/issues?id=:id',
    name: 'app_issues_focus',
    component: () => import('@/views/Issues/app/Issues.vue'),
    beforeEnter,
    meta: {
      auth: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Issues',
      titleHidden: true,
      breadcrumb: [
        {
          text: 'Issues',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/issues?id=:id',
    name: 'issue_details',
    component: () => import('@/views/Issues/IssuesFocus.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Issue Details',
      auth: true,
      breadcrumb: [
        {
          text: 'Issues',
          to: {
            name: 'app_issues',
          },
        },
        {
          text: 'Issue Details',
          active: true,
        },
      ],
    },
  },
]

export default routes

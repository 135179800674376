import beforeEnter from '@/router/modules/models/beforeEnter'

// ORDER IS SIGNIFICANT. More specific routes should come first

const routes = [
  {
    path: '/model/:modelId/behaviour-trees',
    name: 'behaviour_trees',
    component: () => import('@/views/BehaviourTrees/BehaviourTrees.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Behaviour Trees',
      auth: true,
      breadcrumb: [
        {
          text: 'Behaviour Trees',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/behaviour-trees',
    name: 'behaviour_trees_modelling',
    component: () => import('@/views/BehaviourTrees/BehaviourTrees.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Behaviour Trees',
      auth: true,
      breadcrumb: [
        {
          text: 'Behaviour Trees',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/behaviour_tree/:behaviourTreeId?focus=:focus',
    name: 'model_behaviour_tree_focus',
    component: () => import('@/views/Behaviour/BehaviourTree.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Behaviour Tree',
      auth: true,
      breadcrumb: [
        {
          text: 'Behaviour Trees',
          to: { name: 'behaviour_trees' },
        },
        {
          text: 'Behaviour Tree',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/behaviour_tree/:behaviourTreeId',
    name: 'model_behaviour_tree',
    component: () => import('@/views/Behaviour/BehaviourTree.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Behaviour Tree',
      auth: true,
      dynamicTitle: true,
      breadcrumb: [
        {
          text: 'Behaviour Trees',
          to: { name: 'behaviour_trees' },
        },
        {
          text: 'Behaviour Tree',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/bt/:behaviourTreeId',
    name: 'joint_mbse_tree_focus',
    component: () => import('@/views/Behaviour/JointJSGraph/JointJSOrgChart.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Behaviour Tree',
      auth: true,
      dynamicTitle: true,
      breadcrumb: [
        {
          text: 'Behaviour Trees',
          to: { name: 'behaviour_trees' },
        },
        {
          text: 'Behaviour Tree',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/bt/:behaviourTreeId',
    name: 'joint_mbse_tree',
    component: () => import('@/views/Behaviour/JointJSGraph/JointJSOrgChart.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Behaviour Tree',
      auth: true,
      dynamicTitle: true,
      breadcrumb: [
        {
          text: 'Behaviour Trees',
          to: { name: 'behaviour_trees' },
        },
        {
          text: 'Behaviour Tree',
          active: true,
        },
      ],
    },
  },
  {
    path: '/model/:modelId/behaviour_tree_view',
    name: 'model_behaviour_tree_view',
    component: () => import('@/views/Behaviour/BehaviourTreeV.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Behaviour Tree',
      auth: true,
      dynamicTitle: true,
      breadcrumb: [
        {
          text: 'Behaviour Trees',
          to: { name: 'behaviour_trees' },
        },
        {
          text: 'Behaviour Tree',
          active: true,
        },
      ],
    },
  },
]

export default routes

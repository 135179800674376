const routes = [
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/Settings/About.vue'),
    meta: {
      pageTitle: 'Application Details',
      auth: true,
      breadcrumb: [
        {
          text: 'About',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/general',
    name: 'settings_general',
    component: () => import('@/views/Settings/General.vue'),
    meta: {
      pageTitle: 'General Settings',
      auth: true,
      breadcrumb: [
        {
          text: 'General Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/application',
    name: 'settings_application',
    component: () => import('@/views/Settings/Application.vue'),
    meta: {
      pageTitle: 'Application Settings',
      auth: true,
      breadcrumb: [
        {
          text: 'Application Settings',
          active: true,
        },
      ],
    },
  },
]

export default routes

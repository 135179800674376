import axiosIns from '@/libs/axios'
import { apiStatus } from '@/enums'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

const getDefaultState = () => ({
  issues: [],
  issues_status: apiStatus.INIT,
  selected_issue: {},
  selected_issue_comments: [],
  selected_comment: {},
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_ISSUES: (state, uncertainties) => { state.issues = uncertainties },
    SET_SELECTED_ISSUE: (state, selIssue) => { state.selected_issue = selIssue },
    SET_UNCERTAINTIES_STATUS: (state, issuesStatus) => { state.issues_status = issuesStatus },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
  },
  actions: {
    clearIssues: ({ commit }) => { commit('CLEAR_ALL') },
    getIssues: ({ commit }) => {
      const { modelId } = router.currentRoute.params
      commit('SET_UNCERTAINTIES_STATUS', apiStatus.LOADING)
      const params = { model: modelId }
      return axiosIns
        .get('/api/v2/issues', { params })
        .then(({ data }) => {
          commit('SET_ISSUES', data)
          commit('SET_UNCERTAINTIES_STATUS', apiStatus.LOADED)
        })
        .catch(e => console.error(e))
    },
    selectIssue: ({ commit }, issueId) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .get(`/api/v2/issues/${issueId}`)
        .then(({ data }) => {
          commit('SET_SELECTED_ISSUE', data)
        })
        .catch(e => console.error(e))
    },
    updateIssue: ({ dispatch }, issueDetails) => {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .put(`/api/v2/issues/${issueDetails.id}`, { ...issueDetails.data, model: modelId })
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Issue',
              text: 'Successfully updated the Issue',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          dispatch('getIssues')
        })
        .catch(e => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update the Issue',
              text: `Failed to update the Issue, server returned with the error: ${e}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          console.error(e)
        })
    },
  },
}

export class UsersManagementApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get All Users
     * Returns all users of the realm
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllUsers() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users',
        });
    }
    /**
     * Create User
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    createUser(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check User Email Exists
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    checkUserEmailExists(email) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users/check_email/{email}',
            path: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
